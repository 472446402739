@import "global.scss";

.sections{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background: linear-gradient( 30deg, #000, #1b2326);
    z-index: 1;
    overflow: hidden;
    box-sizing: border-box;
    @include mobile_potrait{
        padding-left: 0px;
    }
}

.myToast{
    left: calc(50% - 75px) !important;
    max-width: 300px !important;
    background: #041012 !important;
    border: 1px solid #00e7ff55;
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: row;
    >div{
        color: #fff;
        width: fit-content !important;
        display: flex !important;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row !important;
        font-size: 14px;
    }
    
}

.title{
    flex:0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include mobile_potrait{
        width: 90%;
    }
    h4{
        font-size: 24px;
        color:#00ffc8;
        font-weight: normal;
        margin-bottom: 10px;
        text-transform: uppercase;
        @include mobile_potrait{
            font-size: 0.8rem;
            text-align: center;
        }
        @include mobile_landscape{
            font-size: 0.6rem;
        }
    }
    h1{
        font-size: MIN(3vw, 40px);
        font-weight: 800;
        color: #fff;
        text-align: center;
        margin-bottom: 10px;
        @include mobile_potrait{
            font-size: 1.5rem;
            text-align: center;
        }
        @include mobile_landscape{
            font-size: 1.5rem;
        }
    }
    p{
        font-size: 24px;
        color: #fff;
        // width: 70%;
        text-align: center;
        line-height: 1.5;
        @include mobile_potrait{
            width: 100%;
            font-size: 12px;
        }
        @include mobile_landscape{
            font-size: 0.6rem;
        }
    }
}
.button{
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
    overflow: hidden;
    color: #fff;
    background-color: #106466;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 18px;
    border: none;
    @include mobile_potrait{
        font-size: 16px;
    }
    &.outline{
        background-color: #ffffff00;
        border: 1px #106466 solid;
    }
    
    &:disabled{
        background-image: linear-gradient(to right, #a5a5a5, #686868) ;
        &::before {
            opacity: 0 !important;
            z-index: -1 !important;
        }
        p{
            color: #aaa;
            text-shadow: none;
        }
    }
    &:hover{
        filter: brightness(0.7);
    }
    
}
.walletconnect-modal__base{
    *{
        font-family: 'Montserrat', sans-serif;
    }
}
@keyframes move5 {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    50% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    
}
::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    
    @media screen and (max-width: 648px) and (orientation: portrait) {
      height: 3px;
    }
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #00ffc8;
    border-radius: 5px;
    padding : 5px,
  }
  
  ::-webkit-scrollbar-track {
    background: #001e17;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #32fbcf;
  }
  
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  
  }
  