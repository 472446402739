@import "../../../global.scss";
.press_release{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: auto;
    width: 100%;
    box-sizing: border-box;
    padding-top: 6rem;
    padding-bottom: 5rem;
    @include mobile_potrait{
        padding-bottom: 2rem;
    }

    .content{
        width: 100%;
        display: flex;
        z-index: 1;
        position: relative;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @include mobile_potrait{
            width: 90%;
            height: 90%;
            flex-direction: column;
        }
        .title{
            margin-bottom: 2rem;
            h1{
                // width: 70%;
                text-align: center;
                @include mobile_potrait{
                    width: 100%;
                }
            }
        }
        .wrapper{
            width: 90%;
            display: flex;
            max-width: 1400px;

            @include mobile_potrait{
                flex-direction: column;
                align-items: center;
                justify-content: center;
                max-width:  90%;
            }
           
            ul{
                display: flex;
                justify-content: space-between;
                grid-area: auto;
                gap: 16px;
                padding: 0;
                @include mobile_potrait{
                    flex-direction: column;
                    
                }
                li{
                    width: 25%;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    cursor: pointer;
                    @include mobile_potrait{
                        width: 100%;
                    }
                    &:hover{
                        .circle{
                            transform: rotateY(180deg);
                        }
                    }
                    img{
                        width: 100%;
                        margin-bottom: 1rem;
                    }

                    
                    h2{
                        font-size: 22px;
                        color:#ffecb3;
                        font-weight: normal;
                        margin-bottom: 1rem;
                        @include mobile_potrait{
                            font-size: 0.8rem;
                            text-align: center;
                        }
                        @include mobile_landscape{
                            font-size: 0.6rem;
                        }
                    }
                    p{
                        font-size: 16px;
                        color:#ddd;
                        line-height: 1.5;
                        font-weight: normal;
                        @include mobile_potrait{
                            font-size: 0.8rem;
                            
                        }
                        @include mobile_landscape{
                            font-size: 0.6rem;
                        }
                    }
                    
                }
            }
            
        }
       
        .bg1{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }
    .effect{
        position: absolute;
        width: 30%;
        left: 0;
        bottom: -10%;
        @include mobile_potrait{
            width: 30vw;
        }
    }
    
}