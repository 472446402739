.loading_page {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(166.15deg, rgb(0, 57, 63) -37.37%, rgb(24, 26, 28) 66.42%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  opacity: 0;
  z-index: -1;
  top: 0;
  left: 0;
  @media screen and (max-width: 768px) and (orientation: portrait) {
    height: 100%;
  }
  .logoIcon {
    animation-name: loading;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    opacity: 1;
    position: absolute;
    width: 200px;
    @media screen and (max-width: 768px) and (orientation: portrait) {
    }
    @media screen and (max-height: 768px) and (orientation: landscape) {
      height: 70px;
    }
    @keyframes loading {
      50% {
        opacity: 0.3;
      }
      100% {
        opacity: 1;
      }
    }
  }
  .loading_div{
    width: 200px;
    height: 200px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(-90deg, #00ffc805 -37.37%, #00ffc811 66.42%);
    border-radius: 50%;
    @media screen and (max-width: 768px) and (orientation: portrait) {
      width: 150px;
      height: 150px;
    }
    .icon{
      width: 100%;
      height: 100%;
      border-radius: 100%;
      border: 1px solid #bdfff155;
      display: flex;
      align-items: center;
      justify-content: center;
      animation: loadingAni 1s linear 0s infinite;
      @keyframes loadingAni {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      svg{
        // fill: #bdfff155;
      }

    }
    .circle1{
      width: 50%;
      height: 50%;
      border-radius: 50%;
      border: 1px solid #bdfff155;
      background: linear-gradient(-90deg, #00ffc802 -37.37%, #00ffc805 66.42%);
      position: absolute;
    }
    .circle2{
      width: calc(100% + 16px);
      height: calc(100% + 16px);
      border-radius: 50%;
      border: 1px dashed #bdfff155;
      position: absolute;
    }
    h2{
      position: absolute;
      color:#00ffc8;
      font-size: 32px;
      font-weight: normal;
      @media screen and (max-width: 768px) and (orientation: portrait) {
        font-size: 22px;
      }
    }
  }
}
.activeLoading {
  opacity: 1;
  z-index: 999;
}
