@import "../../../global.scss";
.background_card{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top : 0;
    left: 0;
    z-index: 0;
    position: none;
    @include mobile_potrait{
    }
    .card_content{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 0;
        padding: 16px;
        box-sizing: border-box;
        overflow: hidden;
        @include mobile_landscape{
        }
        .effect1{
            height: 249px;
            position: absolute;
            top: -1px;
            left: 8px;
            opacity: 1;
            color: #ffc000;
            width: fit-content;
            @include mobile_potrait{
                // display: none;
            }
        }
        .effect2{
            height: 249px;
            position: absolute;
            right: 8px;
            bottom: -1px;
            opacity: 1;
            color: #ffc000;
            width: fit-content;
            @include mobile_potrait{
                // display: none;
            }
        }
    }
    .top_right{
        border-top-right-radius: 20px;
    }
    .top_left{
        border-top-left-radius: 20px;
    }
    .bottom_left{
        border-bottom-left-radius: 20px;
    }
    .bottom_right{
        border-bottom-right-radius: 20px;
    }
    .color_01{
        background: linear-gradient(308.45deg, #ffc00066 -94.97%, #ffc0000d 50.06%);
        border: 1px solid #ffc00033;
    }
    .color_02{
        border: 1px solid #00ffc80d;
        background: linear-gradient(132.97deg, #172b2655 28.12%, #004b3b55 100%);
        .effect1{
            color: #3bb2bf;
        }
        .effect2{
            color: #3bb2bf;
        } 
    }
}
