@import "../../../global.scss";
.home{
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    padding-top: 85px;
    @include mobile_potrait{
        height: auto;
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
    .homeContent{
        width: 100%;
        height: calc(100%);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        z-index: 1;
        @include mobile_landscape{
        }
        .homeWrapper{
            width: 90%;
            display: flex;
            align-items: center;
            max-width: 1400px;
            @include mobile_potrait{
                flex-direction: column;
            }
            h1{
                font-size: MIN(8vw, 70px);
                font-weight: normal;
                color: #fff;
                margin-bottom: 24px;
                
                @include mobile_potrait{
                    font-size: 2rem;
                    text-align: center;
                }
                span{
                    color : #D8B08C
                }
            }
            .homeLeft{
                width: 60%;
                display: flex;
                flex-direction: column;
                height: 100%;
                @include mobile_potrait{
                    flex:none;
                    width: 100%;
                    align-items: center;
                    height: auto;
                    padding-top: 5rem;
                }
                
                p{
                    font-size: min(42px, 3vw);
                    color:#fff;
                    margin-bottom: 24px;
                    width: 90%;
                    @include mobile_potrait{
                        font-size: 14px;
                        text-align: center;
                    }
                    
                }
                .socialLinks{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: row;
                    grid-area: auto;
                    width: fit-content;
                    gap : 8px;
                    margin-top: 16px;
                    @include mobile_potrait{
                        margin-bottom: 32px;
                    }
                    a{
                        font-size: 16px;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #98A2B3;
                        position: relative;
                        text-decoration: none;
                        @include mobile_potrait{
                            font-size: 1rem;
                            margin-right: 0rem;
                            width: 1.5rem;
                            height: 1.5rem;
                            font-size: 1rem;
                        }
                        @include mobile_landscape{
                            font-size: 1rem;
                            width: 1.5rem;
                            padding: 0.3rem;
                        }
                        .fab{
                            z-index: 1;
                            font-size: 24px;
                            margin-right: 8px;
                        }
                        
                        &:hover{
                            animation: btnAni 0.5s ease 0s 1 ;
                            @keyframes btnAni {
                                0%{
                                    transform: rotate(0);
                                }
                                20%{
                                    transform: rotate(-10deg);
                                }
                                40%{
                                    transform: rotate(10deg);
                                }
                                60%{
                                    transform: rotate(-10deg);
                                }
                                80%{
                                    transform: rotate(10deg);
                                }
                                100%{
                                    transform: rotate(0);
                                }
                            }
                        }
                    }
                }
                .btns{
                    width: 90%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin-bottom: 1rem;
                    margin-top: 4vh;
                    grid-area: auto;
                    gap: 20px;
                    @include mobile_potrait{
                        justify-content: center;
                        margin-top: 10px;
                        margin-bottom: 10px;
                        // flex-direction: column;
                        gap: 10px;
                        flex-wrap: wrap;
                    }
                    .button{
                        @include mobile_potrait{
                            padding: 0.5rem 1rem !important;
                            margin-bottom: 1rem;

                        }
                        p{
                            font-size: MIN(1rem, 1vw) !important;
                            @include mobile_potrait{
                                font-size: 10px !important;
                            }
                        }
                    }
                    .link_btn{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 8px 24px;
                        white-space: nowrap;
                        border-radius: 16px 0 16px 0;
                        border: 1px solid rgba(255, 192, 0, 0.2);
                        background: linear-gradient(132.97deg, rgba(37, 31, 17, 0.9) 28.12%, rgba(81, 62, 8, 0.9) 100%);
                        &::before {
                            background-color: rgba(255, 192, 0, 0.1);
                        }
                        @include mobile_potrait{
                            padding: 0.5rem 1rem !important;
                        }
                        p{
                            margin-bottom: 0;
                            white-space: nowrap;
                            width: 100%;
                            color: #ffecb3;
                            @include mobile_potrait{
                                font-size: 10px !important;
                            }
                        }
                    }
                }
                ul{
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    flex-direction: row;
                    width: 90%;
                    margin-top: 1rem;
                    li{
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        flex-direction: column;
                        width: 30%;
                        
                        span{
                            width: 100%;
                            h2{
                                color: #fff;
                                font-size: 1.2rem;
                                margin-bottom: 0.5rem;
                                @include mobile_potrait{
                                    font-size: 0.7rem;
                                }
                                @include mobile_landscape{
                                    font-size: 0.7rem;
                                }
                            }
                            p{
                                width: 100%;
                                margin-bottom: 0rem;
                                @include mobile_potrait{
                                    font-size: 0.6rem;
                                    text-align: left;
                                }
                                @include mobile_landscape{
                                    font-size: 0.6rem;
                                    text-align: left;
                                }
                            }
                        }
                        .br{
                            height: 5rem;
                            border-right: 1px #1b5cff solid;
                            @include mobile_potrait{
                                height: 3rem;
                            }
                        }
                    }
                }
            }
            .homeRight{
                width: 40%;
                display: flex;
                justify-content: flex-end;
                height: 100%;
                @include mobile_potrait{
                    width: 100%;
                }
            }
        }
        
        
    }
    .effect1{
        position: absolute;
        top: 0;
        right: 0;
        width: 40%;
        height: 100%;
        object-fit: cover;
        opacity: 0.6;
        pointer-events: none;
        @include mobile_potrait{
            object-fit: cover;
        }
        @include mobile_landscape{
            height: 100%;
        }
    }
}

.modal_content{
    
}


#wert-widget{
    height: 100%;
    width: 100%;
    display: flex;

    @include mobile_potrait{
    }
}