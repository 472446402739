@import "../../../global.scss";
.my_btn{
    width: 100%;
    padding: 32px 20px;
    font-size: 16px;
    box-sizing: border-box;
    color: #fff;
    font-weight: 700;
    transition: all 0.3s ease;
    margin-bottom: 5px;
    
    @include mobile_potrait{
        padding: 24px 20px;
    }
    .button_content{
        width: 100%;
        height: 100%;
        position: absolute;
        .line_1{
            position: absolute;
            height: 100%;
            left: 16px;
            top: 0px;
            pointer-events: none;
            color: inherit;
        }
        .line_2{
            position: absolute;
            height: 100%;
            left: 17px;
            top: 0px;
            pointer-events: none;
            color: inherit;
        }
        .line_3{
            position: absolute;
            height: 100%;
            right: 0px;
            bottom: 0px;
            pointer-events: none;
            color: inherit;
        }
        
    }
    .label{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: 0px 10px 0px 20px;
        font-weight: normal;
        text-transform: uppercase;
        @include mobile_potrait{
            font-size: 12px;
            padding: 0px 0px 0px 10px;
        }
        
        .icon{
            display: inline-flex;
            align-self: center;
            flex-shrink: 0;
            width: 24px;
            height: 24px;
            opacity: 1; 
            
        }
    }
    &.color_01{
        border: 1px solid #ffc00033;
        background: linear-gradient(132.97deg, #251f11e6 28.12%, #513e08e6 100%);
        border-radius: 16px 0px 0px 0px;
        &::before {
            background-color: rgba(255, 192, 0, 0.1);
        }
        .label{
            color: #ffecb3;
        }
        .icon{
            color: #ffc00088;
        }
        
    }
    &.color_02{
        border: 1px solid #00ffc80d;
        background: linear-gradient(132.97deg, #172b26e6 28.12%, #004b3be6 100%);
        border-radius: 0px 0px 16px 0px;
        &::before {
            background-color: #00ffc81a;
        }
        .label{
            color: #bdfff1;
        }
        .icon{
            color: #bdfff1;
        }
        
    }
    &.color_03{
        border: 1px solid #00ffc80d;
        background: linear-gradient(132.97deg, #172b26e6 28.12%, #004b3be6 100%);
        border-radius: 16px 0px 16px 0px;
        &::before {
            background-color: #00ffc81a;
        }
        .label{
            color: #bdfff1;
        }
        .icon{
            color: #bdfff1;
            display: inline-flex;
            align-self: center;
            flex-shrink: 0;
            width: 24px;
            height: 24px;
            opacity: 1;
            svg{
                width: 24px;
                height: 24px;
                display: inline-block;
                line-height: 1em;
                flex-shrink: 0;
                vertical-align: middle;
                fill: none;
            }
        }
        
    }
}

