@import "../../../global.scss";
.exchange_listing{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    height: auto;
    width: 100%;
    box-sizing: border-box;
    padding-top: 6rem;
    padding-bottom: 5rem;
    @include mobile_potrait{
        padding-bottom: 0rem;
    }

    .content{
        width: 100%;
        display: flex;
        z-index: 1;
        position: relative;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-width: 1400px;
        @include mobile_potrait{
            width: 90%;
            height: 90%;
            flex-direction: column;
        }
        .title{
            margin-bottom: 2rem;
            h1{
                text-align: center;
                @include mobile_potrait{
                    width: 100%;
                }
            }
        }
        .wrapper{
            width: 100%;
            display: flex;
            margin-bottom: 5rem;

            @include mobile_potrait{
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
           
            ul{
                display: flex;
                justify-content: space-between;
                grid-area: auto;
                gap: 32px;
                padding: 0;
                width: 100%;
                @include mobile_potrait{
                    gap: 10px;
                    flex-wrap: wrap;
                }
                li{
                    width: 25%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    position: relative;
                    cursor: pointer;
                    border-radius: 24px;
                    backdrop-filter: blur(5px);
                    background: linear-gradient(116.23deg, rgba(16, 100, 102, 0.0875) 2.48%, rgba(16, 100, 102, 0.245) 58.53%, rgba(16, 100, 102, 0.07) 119.46%), conic-gradient(from 169.71deg at 37.5% 31.16%, #FFFFFF01 0deg, rgba(255, 255, 255, 0) 50.63deg, #FFFFFF01 155.62deg, rgba(255, 255, 255, 0) 273.75deg, #FFFFFF01 360deg);
                    border-width: 1px;
                    border-style: dotted;
                    border-color: grey;
                    border-image-source: conic-gradient(from 169.71deg at 37.5% 31.16%, #FFFFFF33 0deg, rgba(255, 255, 255, 0) 50.63deg, #FFFFFF33 155.62deg, rgba(255, 255, 255, 0) 273.75deg, #FFFFFF33 360deg);
                    border-image-slice: 1;
            
                    @include mobile_potrait{
                        width: calc(50% - 10px);
                        border-radius: 16px;
                    }
                    &:hover{
                        img{
                            transform: rotateY(180deg);
                        }
                    }
                    .img_div{
                        width: 100%;
                        height: 0;
                        padding-bottom: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        .img_content{
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                    img{
                        width: 80%;
                        height: 50%;
                        object-fit: contain;
                        transition: all 0.6s ease;
                    }
                    
                }
            }
            
        }
    }
    .effect1{
        position: absolute;
        width: 70%;
        left: 0;
        top: -15%;
        @include mobile_potrait{
            width: 30vw;
        }
    }
    
}