@import "../../../global.scss";
.token{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: auto;
    width: 100%;
    box-sizing: border-box;
    padding-top: 6rem;
    padding-bottom: 5rem;
    @include mobile_potrait{
        padding-bottom: 2rem;
    }

    .tokenContent{
        width: 100%;
        display: flex;
        z-index: 1;
        position: relative;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @include mobile_potrait{
            width: 90%;
            height: 90%;
            flex-direction: column;
        }
        .title{
            margin-bottom: 2rem;
            h1{
                max-width: 900px;
            }
        }
        .wrapper{
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 1400px;
            position: relative;
            box-sizing: border-box;

            border-radius: 24px;
            backdrop-filter: blur(5px);
            background: linear-gradient(116.23deg, rgba(16, 100, 102, 0.0875) 2.48%, rgba(16, 100, 102, 0.245) 58.53%, rgba(16, 100, 102, 0.07) 119.46%), conic-gradient(from 169.71deg at 37.5% 31.16%, #FFFFFF01 0deg, rgba(255, 255, 255, 0) 50.63deg, #FFFFFF01 155.62deg, rgba(255, 255, 255, 0) 273.75deg, #FFFFFF01 360deg);
            border-width: 1px;
            border-style: dotted;
            border-color: grey;
            border-image-source: conic-gradient(from 169.71deg at 37.5% 31.16%, #FFFFFF33 0deg, rgba(255, 255, 255, 0) 50.63deg, #FFFFFF33 155.62deg, rgba(255, 255, 255, 0) 273.75deg, #FFFFFF33 360deg);
            border-image-slice: 1;

            @include mobile_potrait{
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 16px;
            }
            
            .col{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                z-index: 2;
                padding: 32px;
                box-sizing: border-box;
                flex: 1;
                @include mobile_potrait{
                    width: 100%;
                    padding: 24px;
                }
                &.border_right{
                    border-right: 1px solid #7A888945;
                    @include mobile_potrait{
                        border: none;
                        border-bottom: 1px solid #7A888945;
                    }
                }
                .col_content{
                    @include mobile_potrait{
                        width: 100%;
                    }
                }
                span{
                    display: flex;
                    align-items: center;
                    @include mobile_potrait{
                        width: 100%;
                    }
                    img{
                        width: 24px;
                    }
                    .coppyAddressButton {
                        cursor: pointer;
                        border: none;
                        background-color: #ffffff00;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 1rem;
                        transition: all 0.3s ease;
                        &:hover {
                            opacity: 0.7;
                        }
                        
                    }
                    p{
                        @include mobile_potrait{
                            display: flex;
                            width: calc(100% - 50px);
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                }
                h3{
                    font-size: MIN(3vw, 26px);
                    font-weight: normal;
                    color:#fff;
                    margin-bottom: 20px;
                    @include mobile_potrait{
                        font-size: 16px;
                        text-align: center;
                        margin-bottom: 10px;
                    }
                }
                p{
                    font-size: 1rem;
                    color:#fff;

                    @include mobile_potrait{
                        font-size: 0.8rem;
                        text-align: center;
                    }
                    @include mobile_landscape{
                        font-size: 0.6rem;
                    }
                }

            }

        }
    }
    .effect{
        position: absolute;
        width: 40%;
        right: 0;
        @include mobile_potrait{
            width: 30vw;
        }
    }
}