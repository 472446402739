@import "../../../global.scss";
.roadmap{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
    height: auto;
    padding-top: 6rem;
    padding-bottom: 5rem;
    box-sizing: border-box;
    @include mobile_potrait{
        padding-bottom: 2rem;
    }
    .title{
        margin-bottom: 2rem;
        z-index: 1;
    }
    .roadmapContent{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5%;
        margin-bottom: 5rem;
        max-width: 1400px;
        position: relative;
        
        @include mobile_potrait{
            margin-bottom: 0;
            flex-direction: column;
            margin-top: 0;
        }
       
        .col{
            display: flex;
            flex-direction: column;
            flex: 1;
            z-index: 1;
            @include mobile_potrait{
                flex-direction: row;
            }
        }
        .node{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-bottom: 100%;
            position: relative;
            box-sizing: border-box;
            @include mobile_potrait{
                padding-bottom: 1rem;
                width: 100%;
                justify-content: flex-start;
            }
            @include mobile_landscape{
                padding-bottom: 1rem;
            }
            .content{
                width: 100%;
                box-sizing: border-box;
                position: absolute;
                left: 40%;
                
                @include mobile_potrait{
                    position: relative;
                    left: 0;
                }
            }
            .circle{
                position: absolute;
                background-color: #106466;
                width: 24px;
                height: 24px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 1;
                left: calc(31% - 12px);
                @include mobile_potrait{
                    top: 0px;
                    left: 0px;
                    width: 18px;
                    height: 18px;
                }
                
            }
            
            .line{
                position: absolute;
                height: 75%;
                border-right: 2px solid #106466;
                left: 31%;
                @include mobile_potrait{
                    width: 20px;
                    height: 0px;
                    border-right: none;
                    border-bottom: 1px solid #106466;
                    left: 18px;
                }
            }
            .level{
                width: 100%;
                z-index: 1;
                margin-bottom: 10px;
                position: relative;
                color:#D8B08C;
                font-size: 18px;
                font-weight : 800;
                @include mobile_potrait{
                    font-size: 14px;
                    margin-bottom: 0px;
                }
            }
            .desc{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                position: relative;
                box-sizing: border-box;
                @include mobile_potrait{
                    width: calc(100% - 1rem);
                    padding: 10px 10px;
                }
               
                p{
                    color: #fff;
                    font-size: 18px;
                    line-height: 1.5;
                    text-align: left;
                    @include mobile_potrait{
                        font-size: 12px;
                        line-height: 1.5;
                        width: 100%;
                    }
                    
                }

            }
        }
        .top{
            @include mobile_potrait{
                border-bottom: none;
                padding-right: 0;
                padding-left: 40px;
            }
            .circle{
                bottom: 100%;
                @include mobile_potrait{
                    bottom: auto;
                }
            }
            .line{
                bottom: 25%;
                @include mobile_potrait{
                    bottom: calc(100% - 9px);
                }
            }
            .content{
                top: -24px;
                @include mobile_potrait{
                    bottom: auto;
                    top: 0;
                }
            }
        }
        .bottom{
            top: 0;
            @include mobile_potrait{
                padding-left: 40px;
                border-top: none;
            }
            .circle{
                top: calc(65%);
                @include mobile_potrait{
                    left: 0px;
                    top: 0;
                }
            }
            .line{
                z-index: -1;
                height: 20%;
                top: 45%;
                @include mobile_potrait{
                    left: 18px;
                    height: 0;
                    bottom: auto;
                    top: 9px;
                }
            }
            .content{
                bottom: -24px;
                @include mobile_potrait{
                    bottom: auto;



                }
            }
        }
        .none{
            @include mobile_potrait{
                display: none;
            } 
        }
    }
    .bg{
        position: absolute;
        width: 92.5%;
        opacity: 1;
        left: 0;
        @include mobile_potrait{
            display: none;
        }
    }
}