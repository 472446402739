@import "../../../global.scss";
.sidebar{
    // height: 110vh;
    position: fixed;
    width: 300px;
    left: -100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.5s ease;
    box-sizing: border-box;
    z-index: 15;
    position: fixed;
    top: 220px;
    @include mobile_potrait{
        top: 0rem;
        width: 100vw;
        left: -100vw;
        background-color: rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(15px);
        padding-top: 80px;
        height: 100vh;
    }
    
    &.active{
        left: 0;
    }
    .content{
        display: flex;
        flex-direction: column;
        width: 100%;
        box-sizing: border-box;
        padding: 24px 0px 24px 0px;
        position: relative;
        .lines{
            display: flex;
            flex-direction: column;
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
            @include mobile_potrait{
                display: none;
            }
            .line1{
                width: calc(100% - 39px);
                border-right: 1px solid #005543;
                height: 26px;
            }
            .bottom_line{
                width: 100%;
                height: 49px;
                color: #00ffc8;
                svg{
                    height: 100%;
                    width: 100%;
                }
            }
            .top_line{
                width: 100%;
                height: 49px;
                color: #00ffc8;
            }
            .middle_line{
                width: 100%;
                height: 132px;
                color: #00ffc8;
            }
        }
        .close_btn{
            color: #00ffc8;
            width: 18px;
            height: 18px;
            position: absolute;
            right: 30px;
            top : 48%;
            transform: rotate(90deg);
            z-index: 1;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease;
            font-size: 14px;
            @include mobile_potrait{
                display: none;
            }
        }
        
    }
    
    ul{
        margin-top: 1rem;
        padding: 0;
        list-style: none;
        font-weight: 400;
        color:black;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        font-size: 0.8rem;
        padding-left: 32px;
        box-sizing: border-box;
        z-index: 1;
        .menuItem1{
            transition: left 1s ease;
            transition-delay: 0.1s;
        }
        .menuItem2{
            transition:  left 1s ease;
            transition-delay: 0.15s;
        }
        .menuItem3{
            transition:  left 1s ease;
            transition-delay: 0.2s;
        }
        .menuItem4{
            transition:  left 1s ease;
            transition-delay: 0.25s;
        }
        .menuItem5{
            transition:  left 1s ease;
            transition-delay: 0.3s;
        }
        .menuItem6{
            transition:  left 1s ease;
            transition-delay: 0.35s;
        }
        .menuItem7{
            transition:  left 1s ease;
            transition-delay: 0.4s;
        }
        .menuItem8{
            transition:  left 1s ease;
            transition-delay: 0.45s;
        }
        .menuItem9{
            transition:  left 1s ease;
            transition-delay: 0.5s;
        }
        li{
            position: relative;
            transition: left 0s ease;
            margin-bottom: 20px;
            transition: all 0.3s ease;
            @include mobile_potrait{
                margin-bottom: 16px;
            }
            a{
                text-decoration: none;
                font-size: 12px;
                font-weight: normal;
                color: #bdfff1;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                
                .fas{
                    margin-right: 8px;
                    
                }
                .fab{
                    font-size: 20px;
                    margin-right: 8px;
                }
            }
            &:hover{
                opacity: 0.3;
            }
            
            &.active{
                right:0;
            }
        }
        .selected{
            a{
                color: #00ffc8;
            }
        }
    }
} 