@import "../../../global.scss";
.video_section{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    // overflow: hidden;
    height: auto;
    width: 100%;
    padding-top: 6rem;
    padding-bottom: 5rem;
    box-sizing: border-box;
    @include mobile_potrait{
        padding-bottom: 2rem;
    }

    .content{
        width: 90%;
        z-index: 1;
        position: relative;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mobile_potrait{
            width: 90%;
            height: 90%;
            flex-direction: column;
        }
        
        .title{
            margin-bottom: 2rem;
        }
        .wrapper{
            width: 100%;
            max-width: 1200px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include mobile_potrait{
                flex-direction: column;
            }
            .video_div{
                position: relative;
                padding: 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 18px;
                border: 1px #00ffc855 solid;
                overflow: hidden;
                @include mobile_potrait{
                }
                .img_div{
                    position: absolute;
                    // width: calc(100% - 40px);
                    width: 100%;
                    height: 100%;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .videoContent{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // width: 100%;
                    width: 800px;
                    max-width: 800px;
                    height: auto;
                    position: relative;
                    transition: all 0.3s ease;
                    z-index: 1;
                    border-radius: 18px;
                    border: 1px #00ffc855 solid;
                    opacity: 0;
                    @include mobile_potrait{
                        width: 100%;
                        transform: scale(0.9);
                        margin-bottom: 0rem;
                    }
                }
                
            }
            .video_tag{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                width: 900px;
                max-width: 900px;
                height: 508px;
                position: relative;
                transition: all 0.3s ease;
                z-index: 1;
                overflow: hidden;
                @include mobile_potrait{
                    width: 85vw;
                    margin-bottom: 0rem;
                    height: 48vw;
                }
                
                .videoEmbed{
                    width: 100%;
                    height: 100%;
                }
            }
            .play_btn{
                position: absolute;
                z-index: 1;
                border-radius: 75px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px dashed #00ffc888;
                height: 85px;
                width: 85px;
                padding: 0px;
                cursor: pointer;
                &:hover{
                    .icon{
                        background-color: #00ffc811;
                        &:after{
                            opacity: 1;
                        }
                    }  
                }
                .icon{
                    display: inline-flex;
                    align-self: center;
                    flex-shrink: 0;
                    border: 1px solid #00ffc8;
                    height: 75px;
                    width: 75px;
                    border-radius: 75px;
                    opacity: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.3s ease;
                    position: relative;
                    svg{
                        width: 38px;
                        height: 38px;
                        display: inline-block;
                        line-height: 1em;
                        flex-shrink: 0;
                        color: rgb(189, 255, 241);
                        vertical-align: middle;
                        fill: none;
                        transform: scaleX(-1);
                    }
                    &:after{
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        border: 1px solid #00ffc855;
                        border-radius: 50px;
                        transition: all 0.3s ease;
                        animation: buttonAni 0.5s ease 0s infinite;
                        opacity: 0;
                        @keyframes buttonAni {
                            0%{
                                transform: scale(0.5);
                                
                            }
                            100%{
                                transform: scale(1.3);
                            }
                        }
                    }
                }
               
            }
        }
       
        .bg1{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }
    .effect{
        position: absolute;
        width: 45%;
        right: 0;
    }
}