@import "../../../global.scss";
.my_btn2{
    width: 100%;
    padding: 20px 20px;
    font-size: 16px;
    box-sizing: border-box;
    color: #fff;
    font-weight: 700;
    transition: all 0.3s ease;
    margin-bottom: 5px;
    
    @include mobile_potrait{
    }
    .button_content{
        width: 100%;
        height: 100%;
        position: absolute;
        .line_1{
            position: absolute;
            height: 100%;
            left: 16px;
            top: 0px;
            pointer-events: none;
            color: inherit;
        }
        .line_2{
            position: absolute;
            height: 100%;
            left: 17px;
            top: 0px;
            pointer-events: none;
            color: inherit;
        }
        .line_3{
            position: absolute;
            height: 100%;
            right: 0px;
            bottom: 0px;
            pointer-events: none;
            color: inherit;
        }
        
    }
    .label{
        display: flex;
        align-items: center;
        width: 100%;
        // justify-content: space-between;
        padding: 0px 10px 0px 20px;
        font-weight: normal;
        text-transform: uppercase;
        white-space: nowrap;
        img{
            width: 40px;
            margin-right: 8px;
        }
        .fas{
            margin-right: 8px;
            font-size: 32px;
        }
    }
    &.top_left{
        border-radius: 16px 0px 0px 0px;
    }
    &.top_right{
        border-radius: 0px 16px 0px 0px;
    }
    &.bottom_left{
        border-radius: 0px 0px 0px 16px;
    }
    &.bottom_right{
        border-radius: 0px 0px 16px 0px;
    }
    &.color_01{
        border: 1px solid #ffc00033;
        background: linear-gradient(132.97deg, #251f11e6 28.12%, #513e08e6 100%);
        &::before {
            background-color: rgba(255, 192, 0, 0.1);
        }
        .label{
            color: #ffecb3;
        }
        
    }
    &.color_02{
        border: 1px solid #00ffc80d;
        background: linear-gradient(132.97deg, #172b26e6 28.12%, #004b3be6 100%);
        &::before {
            background-color: #00ffc81a;
        }
        .label{
            color: #bdfff1;
        }
        
    }
}

