@import "../../../global.scss";
.modal{
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.61);
    z-index: 5;
    display: none;
    backdrop-filter: blur(5px);
    &.active{
        display: flex;
    }
    .model_content{
        z-index: 5;
        width: 400px;
        background: #000000de;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        position: relative;
        padding: 24px;
        backdrop-filter: blur(5px);
        background: linear-gradient(116.23deg, rgba(16, 100, 102, 0.0875) 2.48%, rgba(16, 100, 102, 0.245) 58.53%, rgba(16, 100, 102, 0.07) 119.46%), conic-gradient(from 169.71deg at 37.5% 31.16%, #FFFFFF01 0deg, rgba(255, 255, 255, 0) 50.63deg, #FFFFFF01 155.62deg, rgba(255, 255, 255, 0) 273.75deg, #FFFFFF01 360deg);
        border-width: 1px;
        border-style: dotted;
        border-color: grey;
        border-image-source: conic-gradient(from 169.71deg at 37.5% 31.16%, #FFFFFF33 0deg, rgba(255, 255, 255, 0) 50.63deg, #FFFFFF33 155.62deg, rgba(255, 255, 255, 0) 273.75deg, #FFFFFF33 360deg);
        border-image-slice: 1;
            
        @include mobile_potrait{
            width: 90vw;
        }
        
        
        .modal_header{
          width: 100%;
          border-top-left-radius: 2rem;
          border-top-right-radius: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          z-index: 1;
          @include mobile_potrait{
              border-top-left-radius: 1rem;
              border-top-right-radius: 1rem;
          }
          @include mobile_landscape{
              border-top-left-radius: 1rem;
              border-top-right-radius: 1rem;
          }

          .modal_title{
              
            font-size: 20px;
            font-weight: 800;
            max-width: 800px;
            margin-bottom: 10px;
            color: #00ffc8bb;
            text-transform: uppercase;
            text-align: center;
          }
          .connectModalCloseButton{
              font-size: 2rem;
              font-weight: 600;
              border: none;
              background-color: #00000000;
              border-radius: 16px;
              cursor: pointer;
              color: #00ffc8bb;
              margin-right: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: all 0.3s ease;
              position: absolute;
              top: 8px;
              right: 8px;
              &:hover{
                  transform: rotate(45deg);
              }
          }
      }
        
        .modal_wrapper{
            width: 100%;
            // height: 17em;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            position: relative;
            z-index: 1;
            @include mobile_potrait{
              max-height: 60vh;
              overflow: hidden;
              overflow-y: scroll;
            }
            .connectModalBtn {
                display: flex;
                cursor: pointer;
                width: calc(100% - 3rem);
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                margin-top: 0.8rem;
                display: flex;
                position: relative;
        
                &:hover {
                  .left {
                    .icon {
                      img {
                        filter: drop-shadow(3px 3px 5px #000);
                      }
                    }
                  }
                  .middle {
                    h2 {
                      text-shadow: 2px 2px 5px #000;
                    }
                  }
                }
                .btn_bg {
                  position: absolute;
                  width: 100%;
                  left: 0;
                  height: 100%;
                  top: 0;
                }
                .left {
                  flex: 0.2;
                  margin-top: 0rem;
                  z-index: 1;
                  .icon {
                    width: 3.5rem;
                    height: 3.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    @include mobile_potrait {
                      width: 3rem;
                      height: 3rem;
                    }
                    @include mobile_landscape {
                      width: 3rem;
                      height: 3rem;
                    }
                    img {
                      width: 80%;
                      height: 80%;
                      transition: all 0.3s ease;
                      filter: drop-shadow(2px 2px 0px #000);
                    }
                  }
                }
                .middle {
                  flex: 0.8;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  margin-top: 0rem;
                  z-index: 1;
        
                  h2 {
                    font-size: 1.3rem;
                    width: 90%;
                    color: #aaa;
                    transition: all 0.3s ease;
                    font-family: 'Montserrat', sans-serif;
                    @include mobile_potrait {
                      font-size: 1.2rem;
                    }
                    @include mobile_landscape {
                      font-size: 1.2rem;
                    }
                  }
                  p {
                    font-size: 12px;
                    width: 90%;
                    color: #777;
                    @include mobile_potrait {
                      font-size: 0.8rem;
                    }
                    @include mobile_landscape {
                      font-size: 0.8rem;
                    }
                  }
                }
              }
            
        }
        
        .bar{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
        }
    }
}
