@import "../../../global.scss";
.footer{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: 5rem 32px;
    // border: 0px, 0px, 1px, 0px;
    border-top: 1px solid;

    border-image-source: conic-gradient(from 169.71deg at 37.5% 31.16%, rgba(255, 255, 255, 0.68) 0deg, rgba(255, 255, 255, 0) 50.63deg, #FFFFFF 155.62deg, rgba(255, 255, 255, 0) 273.75deg, rgba(255, 255, 255, 0.68) 360deg);


    background: linear-gradient(116.23deg, rgba(16, 100, 102, 0.0875) 2.48%, rgba(16, 100, 102, 0.245) 58.53%, rgba(16, 100, 102, 0.07) 119.46%);//, conic-gradient(from 169.71deg at 37.5% 31.16%, rgba(255, 255, 255, 0.037) 0deg, rgba(255, 255, 255, 0) 50.63deg, #ffffff0b 155.62deg, rgba(255, 255, 255, 0) 273.75deg, rgba(255, 255, 255, 0.06) 360deg);
    backdrop-filter: blur(10px);


    @include mobile_potrait{
        padding: 3rem 0px;
    }
    .footerContent{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        box-sizing: border-box;
        z-index: 1;
        max-width: 1200px;
        @include mobile_potrait{
            flex-direction: column;
        }
        
        .wrapper{
            display: flex;
            width: 100%;
            margin-bottom: 5rem;
            grid-area: auto;
            gap: 24px;
            @include mobile_potrait{
                width: 90%;
                flex-direction: column;
                margin-bottom: 3rem;
            }
            .left{
                width: 45%;
                display: flex;
                flex-direction: column;
                @include mobile_potrait{
                    width: 100%;
                }
                .logo{
                    width: 200px;
                    margin-bottom: 32px;
                }
                p{
                    font-size: 18px;
                    color: #ffffffb3;
                    margin-bottom: 64px;
                    @include mobile_potrait{
                        font-size: 15px;
                        margin-bottom: 32px;
                    }
                    span{
                        color: #F3BA2F;
                    }
                }
                
            }
            .right{
                width: 55%;
                display: flex;
                justify-content: space-between;
                @include mobile_potrait{
                    width: 100%;
                    flex-wrap: wrap;
                }
                .col{
                    // width: 25%;
                    display: flex;
                    flex-direction: column;
                    grid-area: auto;
                    gap : 16px;
                    @include mobile_potrait{
                        width: 45%;
                        gap : 10px;
                        margin-bottom: 2rem;
                    }
                    h3{
                        color: #fff;
                        
                        font-size: 18px;

                    }
                    a{
                        color: #fff;
                        font-size: 20px;
                        text-decoration: none;
                        transition: all 0.3s ease;
                        @include mobile_potrait{
                            font-size: 15px;
                        }
                        &:hover{
                            opacity: 0.7;
                        }
                    }

                }
            }
            
            
            
        }
        .foot{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            @include mobile_potrait{
                flex-direction: column-reverse;
            }
            .socialLinks{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                grid-area: auto;
                width: fit-content;
                gap : 16px;
                @include mobile_potrait{
                }
                a{
                    font-size: 16px;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #98A2B3;
                    position: relative;
                    text-decoration: none;
                    @include mobile_potrait{
                        font-size: 1rem;
                        margin-right: 0rem;
                        width: 1.5rem;
                        height: 1.5rem;
                        font-size: 1rem;
                    }
                    @include mobile_landscape{
                        font-size: 1rem;
                        width: 1.5rem;
                        padding: 0.3rem;
                    }
                    .fab{
                        z-index: 1;
                        font-size: 24px;
                        margin-right: 8px;
                    }
                    
                    &:hover{
                        animation: btnAni 0.5s ease 0s 1 ;
                        @keyframes btnAni {
                            0%{
                                transform: rotate(0);
                            }
                            20%{
                                transform: rotate(-10deg);
                            }
                            40%{
                                transform: rotate(10deg);
                            }
                            60%{
                                transform: rotate(-10deg);
                            }
                            80%{
                                transform: rotate(10deg);
                            }
                            100%{
                                transform: rotate(0);
                            }
                        }
                    }
                }
            }
            span{
                text-align: center;
                color: #ffffffb3;
                font-size: 18px;
                display: flex;
                grid-area: auto;
                gap : 24px;
                @include mobile_potrait{
                    font-size: 14px;
                }
                a{
                    color: #ffffffb3;
                    text-decoration: none;
                    transition: all 0.3s ease;
                    @include mobile_potrait{
                        margin-bottom: 2rem;
                        
                    }
                    &:hover{
                        color: #fff;
                    }
                }
            }
        }
        
    }
    
}