@import "../../../global.scss";
.tokenModal{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left : 0;
    margin: 0;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.61);
    z-index: 25;
    display: none;
    opacity: 1;
    backdrop-filter: blur(5px);
    &.active{
        display: flex;
    }
    .modelContent{
        z-index: 5;
        width: 22rem;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        position: relative;
        opacity: 1;
        padding: 24px;
        @include mobile_potrait{
            width: 20rem;
            // height: 18rem;
        }
        
        // animation: modalAni 0.5s ease 0s 1 forwards;
        // @keyframes modalAni {
        //     0%{
        //         transform: scale(0);
        //     }
        //     80%{
        //         transform: scale(1.2);
        //     }
        //     100%{
        //         transform: scale(1);
        //     }
        // }
        .connectWalletHeader{
            width: 100%;
            // border-bottom: 1px solid #3bb2bfba;
            border-top-left-radius: 2rem;
            border-top-right-radius: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            z-index: 1;
            @include mobile_potrait{
                border-top-left-radius: 1rem;
                border-top-right-radius: 1rem;
            }
            @include mobile_landscape{
                border-top-left-radius: 1rem;
                border-top-right-radius: 1rem;
            }
            .wallet_svg{
              display: inline-flex;
              align-self: center;
              flex-shrink: 0;
              width: 140px;
              height: 140px;
              margin-bottom: 16px;
              svg{
                width: 140px;
                height: 140px;
                display: inline-block;
                line-height: 1em;
                flex-shrink: 0;
                color: rgb(0, 255, 200);
                vertical-align: middle;
              }
            }
            .connectWalletTitle{
                
              font-size: 20px;
              font-weight: normal;
              max-width: 800px;
              margin-bottom: 10px;
              -webkit-text-stroke-width: 0.3px;
              -webkit-text-stroke-color: #00ffc8bb;
              -webkit-text-fill-color: transparent;
              text-transform: uppercase;
              text-align: center;
            }
            .connectModalCloseButton{
                font-size: 2rem;
                font-weight: 600;
                border: none;
                background-color: #00000000;
                border-radius: 16px;
                cursor: pointer;
                -webkit-text-stroke-width: 0.3px;
                -webkit-text-stroke-color: #00ffc8bb;
                -webkit-text-fill-color: transparent;
                margin-right: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.3s ease;
                position: absolute;
                top: 8px;
                right: 8px;
                &:hover{
                    animation: closeAni 0.5s ease 0s infinite;
                    @keyframes closeAni {
                        0%{
                            transform: translateX(0);
                        }
                        50%{
                            transform: translateX(0.5rem);
                        }
                        100%{
                            transform: translateX(0);
                        }
                    }
                }
            }
        }
        
        .connectWalletWrapper{
            width: 90%;
            // height: 17em;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            position: relative;
            z-index: 1;
            p{
              font-size: 12px;
              font-weight: normal;
              color: #bdfff1;
              display: flex;
              align-items: center;
              margin-bottom: 16px;
              margin-top: 20px;
              line-height: 2;
            }
            .connectModalBtn {
                display: flex;
                cursor: pointer;
                width: calc(100% - 3rem);
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                margin-top: 0.8rem;
                display: flex;
                position: relative;
        
                &:hover {
                  .left {
                    .icon {
                      img {
                        filter: drop-shadow(3px 3px 5px #000);
                      }
                    }
                  }
                  .middle {
                    h2 {
                      text-shadow: 2px 2px 5px #000;
                    }
                  }
                }
                .btn_bg {
                  position: absolute;
                  width: 100%;
                  left: 0;
                  height: 100%;
                  top: 0;
                }
                .left {
                  flex: 0.2;
                  margin-top: 0rem;
                  z-index: 1;
                  .icon {
                    width: 3.5rem;
                    height: 3.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    @include mobile_potrait {
                      width: 3rem;
                      height: 3rem;
                    }
                    @include mobile_landscape {
                      width: 3rem;
                      height: 3rem;
                    }
                    img {
                      width: 80%;
                      height: 80%;
                      transition: all 0.3s ease;
                      filter: drop-shadow(2px 2px 0px #000);
                    }
                  }
                }
                .middle {
                  flex: 0.8;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  margin-top: 0rem;
                  z-index: 1;
        
                  h2 {
                    font-size: 1.3rem;
                    width: 90%;
                    color: #aaa;
                    transition: all 0.3s ease;
                    font-family: 'Montserrat', sans-serif;
                    @include mobile_potrait {
                      font-size: 1.2rem;
                    }
                    @include mobile_landscape {
                      font-size: 1.2rem;
                    }
                  }
                  p {
                    font-size: 12px;
                    width: 90%;
                    color: #777;
                    @include mobile_potrait {
                      font-size: 0.8rem;
                    }
                    @include mobile_landscape {
                      font-size: 0.8rem;
                    }
                  }
                }
              }
            
        }
        
        .bar{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
        }
    }
}