@import "../../global.scss";


.ico_section{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // min-height: calc(100vh - 5rem);
    // margin-top: 5rem;
    width: 100%;
    z-index: 10;
    position: relative;

    @include mobile_potrait{
        margin-top: 0;
    }

    .wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 1130px;
        width: 100%;
        @include mobile_potrait{
            width: 100%;
        }

        .card{
            width: 100%;
            max-width: 550px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            overflow: hidden;
            position: relative;
            border-radius: 24px;
            backdrop-filter: blur(5px);
            background: linear-gradient(116.23deg, rgba(16, 100, 102, 0.0875) 2.48%, rgba(16, 100, 102, 0.245) 58.53%, rgba(16, 100, 102, 0.07) 119.46%), conic-gradient(from 169.71deg at 37.5% 31.16%, #FFFFFF01 0deg, rgba(255, 255, 255, 0) 50.63deg, #FFFFFF01 155.62deg, rgba(255, 255, 255, 0) 273.75deg, #FFFFFF01 360deg);
            border-width: 1px;
            border-style: dotted;
            border-color: grey;
            border-image-source: conic-gradient(from 169.71deg at 37.5% 31.16%, #FFFFFF33 0deg, rgba(255, 255, 255, 0) 50.63deg, #FFFFFF33 155.62deg, rgba(255, 255, 255, 0) 273.75deg, #FFFFFF33 360deg);
            border-image-slice: 1;
            @include mobile_potrait{
                border-radius: 16px;
            }
            .card_top{
                width: 100%;
                z-index: 2;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                box-sizing: border-box;
                padding: 18px 24px;
                padding-top: 10px;
                grid-area: auto;
                gap : 10px;
                position: relative;
                border-bottom: 1px #42424277 solid;
                @include mobile_potrait{
                    padding: 18px 16px !important;
                    
                }
                .timer_div{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-bottom: 1px solid #d3d3d3;
                    padding-bottom: 10px;
                }
                
                .loading-div1{
                    width: 90%;
                    height: 58px;
                    background-color: #fff;
                    border-radius: 16px;
                    opacity: 0.3;
                    animation: loadingBlinking 1s ease 0s infinite;
                    margin-bottom: 10px;
                }
                .loading-div2{
                    width: 100%;
                    height: 12px;
                    background-color: #fff;
                    border-radius: 10px;
                    opacity: 0.3;
                    animation: loadingBlinking 1s ease 0.3s infinite;
                }

                .loading-div3{
                    width: 90%;
                    height: 20px;
                    background-color: #fff;
                    border-radius: 16px;
                    opacity: 0.3;
                    animation: loadingBlinking 1s ease 0.5s infinite;
                }
                .loading-div4{
                    width: 60%;
                    height: 15px;
                    background-color: #fff;
                    border-radius: 16px;
                    opacity: 0.3;
                    animation: loadingBlinking 1s ease 0.1s infinite;
                }
                .amount{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    h2{
                        color: #fff;
                        font-weight: normal;
                        font-size: 18px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        overflow: hidden;
                        letter-spacing: -1px;
                        grid-area: auto;
                        span{
                            color: #8DD4D6;
                            margin-right: 8px;
                            font-weight: 600;
                        }
                        @include mobile_potrait{
                            font-size: 10px;
                        }
                    }
                    .claimBtn{
                        width: 100%;
                        background-color: #F3B34388;
                        border-radius: 8px;
                        padding: 10px 20px;
                        font-size: 16px;
                        border: 1px #F3B343 solid;
                        cursor: pointer;
                        box-sizing: border-box;
                        color: #fff;
                        font-weight: normal;
                        transition: all 0.3s ease;
                        text-shadow: 1px 1px 0px #000;
                        &:hover{
                            background-color: #a8782622;
                        }
                        &:disabled{
                            color: #d3d3d3 !important;
                        }
                    }
                }
            }
            .card_content{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                box-sizing: border-box;
                padding: 20px 20px;
                grid-area: auto;
                gap : 16px;
                width: 100%;
                z-index: 2;
                @include mobile_potrait{
                    // padding: 0;
                }
                h2{
                    color: #fff;
                    font-size: 40px;
                    font-weight: 800;
                    text-align: center;
                    width: 100%;
                    span{
                        color: #8DD4D6;
                    }
                    @include mobile_potrait{
                        font-size: 32px;
                    }
                    
                }
                
                .progress-div{
                    width: 100%;
                    height: 23px;
                    background-color: #fff;
                    border-radius: 23px;
                    overflow: hidden;
                    display: flex;
                    position: relative;
                    align-items: center;
                    @include mobile_potrait{
                        height: 20px;
                    }
                    .progress{
                        height: 100%;
                        background-color: #079455;
                        border-radius: 23px;
                    }
                    p{
                        position: absolute;
                        width: 100%;
                        left: 0;
                        font-size: 14px;
                        text-align: center;
                        @include mobile_potrait{
                            font-size: 12px;
                        }

                    }
                }
                h3{
                    color: #fff;
                    font-size: 20px;
                    font-weight: normal;
                    text-align: center;
                    width: 100%;
                    span{
                        color: #8DD4D6;
                    }
                    @include mobile_potrait{
                        font-size: 16px;
                    }
                }
                
                .btns{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    grid-area: auto;
                    gap: 5px;
                    @include mobile_potrait{
                        flex-direction: column;
                    }
                    .coin_label{
                        display: flex;
                        align-items: center;
                        color: #ffecb3;
                        font-size: 14px;
                        padding: 0 10px;
                        @include mobile_potrait{
                            font-size: 12px;
                        }
                        img{
                            width: 24px;
                            height: 24px;
                            margin-right: 8px;
                            @include mobile_potrait{
                                width: 20px;
                                height: 20px;
                                margin-right: 5px;
                            }
                        }
                    }
                    .filter_select{
                        width: 50%;
                    }
                    .button{
                        flex: 1;
                    }
                }
                .col_div{
                    width: 100%;
                    box-sizing: border-box;
                    grid-area: auto;
                    gap : 16px;
                    display: flex;
                    flex-direction: column;
                    
                }
                .row{
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    width: 100%;
                    h4{
                        font-size: 16px;
                        color:#fff;
                        font-weight: normal;
                        width: 100%;
                        margin-bottom: 5px;
                        span{
                            color: #F3B343;
                            font-weight: 600;
                            font-size: 14px;
                        }
                    }
                    .input_div{
                        display: flex;
                        position: relative;
                        width: 100%;
                        height: 54px;
                        border-radius: 8px;
                        overflow: hidden;
                        background-color: rgba(255, 255, 255, 0.1);
                        @include mobile_potrait{
                            height: 46px;
                        }
                        input{
                            width: calc(100% - 148px - 52px);
                            flex: 1;
                            padding: 10px;
                            border: none;
                            box-sizing: border-box;
                            color: #fff;
                            font-weight: 500;
                            background-color: #ffffff00;
                            font-size: 18px;
                            &:focus{
                                outline: none;
                            }
                            @include mobile_potrait{
                                width: calc(100% - 120px - 52px);
                            }
                        }
                        input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
                            display: none;
                        }
                        .img_div{
                            // height: 100%;
                            display: flex;
                            align-items: center;
                            color: #ffecb3;
                            font-size: 14px;
                            padding: 0 10px;
                            box-sizing: border-box;
                            @include mobile_potrait{
                                font-size: 12px;
                            }
                            img{
                                width: 24px;
                                height: 24px;
                                @include mobile_potrait{
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                            
                        }
                        
                        
                    }
                    .full_w{
                        width: 100%;
                    }
                    
                }
                .button{
                    grid-area: auto;
                    gap : 10px;
                    height: 54px;
                    white-space: nowrap;
                    @include mobile_potrait{
                        gap : 4px;
                        height: 46px;
                    }
                    &.outline{
                        background-color: #ffffff00;
                        border: 1px solid #106466;
                    }
                    .bnb_icon{
                        height: 28px;
                        @include mobile_potrait{
                            height: 24px;
                        }
                    }
                }
                .launchBtn{
                    font-size: 12px;
                    color: #d3d3d3;
                    text-decoration: none;
                    transition: all 0.3s ease;
                    cursor: pointer;
                    &:hover{
                        color: #fff;
                    }
                }
                
            }
            .effect1{
                height: 249px;
                position: absolute;
                top: -1px;
                left: 8px;
                opacity: 1;
                color: #ffc000;
                @include mobile_potrait{
                    display: none;
                }
            }
            .effect2{
                height: 249px;
                position: absolute;
                right: 8px;
                bottom: -1px;
                opacity: 1;
                color: #ffc000;
                @include mobile_potrait{
                    display: none;
                }
            }
        }
        p{
            color: #fff;
            font-size: 16px;
            @include mobile_potrait{
                width: 90%;
                font-size: 16px;
                text-align: center;
                img{
                    display: none;
                }
            }
        }
        h5{
            color: #fff;
            font-size: 12px;
            font-weight: normal;
            @include mobile_potrait{
                width: 90%;
                font-size: 16px;
                text-align: center;
                img{
                    display: none;
                }
            }
            span{
                font-weight: 700;
            }
        }

    }
}

#wert-widget{
    height: 100%;
    width: 100%;
    display: flex;

    @include mobile_potrait{
    }
}