@import "../../../global.scss";
.faq{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;
    @include mobile_potrait{
        height: auto;
        padding-bottom: 2rem;
    }
    
    .faqContent{
        width: 80%;
        margin-bottom: 2vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        @include mobile_potrait{
            width: 90%;
            height: auto;
            margin-bottom: 0;
        }
        .title{
            margin-bottom: 3rem;
        }
        .wrapper{
            width: 100%;
            max-width: 1200px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            z-index: 1;
            position: relative;
            padding: 60px;
            box-sizing: border-box;
            @include mobile_potrait{
                height: auto;
                flex-direction: column;
                padding: 0px;
            }
            .left{
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                z-index: 1;

                border-radius: 24px;
                backdrop-filter: blur(5px);
                background: linear-gradient(116.23deg, rgba(16, 100, 102, 0.0875) 2.48%, rgba(16, 100, 102, 0.245) 58.53%, rgba(16, 100, 102, 0.07) 119.46%), conic-gradient(from 169.71deg at 37.5% 31.16%, #FFFFFF01 0deg, rgba(255, 255, 255, 0) 50.63deg, #FFFFFF01 155.62deg, rgba(255, 255, 255, 0) 273.75deg, #FFFFFF01 360deg);
                border-width: 1px;
                border-style: dotted;
                border-color: grey;
                border-image-source: conic-gradient(from 169.71deg at 37.5% 31.16%, #FFFFFF33 0deg, rgba(255, 255, 255, 0) 50.63deg, #FFFFFF33 155.62deg, rgba(255, 255, 255, 0) 273.75deg, #FFFFFF33 360deg);
                border-image-slice: 1;
                box-sizing: border-box;
                padding: 46px 24px;
                @include mobile_potrait{
                    width: 100%;
                    border-radius: 16px;
                }
                .line{
                    width: calc(100% - 2rem);
                    padding: 1rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    transition: all 0.4s ease;
                    border-bottom: 1px #EAECF0 solid;
                    margin-bottom: 1rem;
                    position: relative;
                    cursor: pointer;
                    overflow: hidden;
                    @include mobile_potrait{
                        width: calc(100% - 1rem);
                        padding: 0.5rem;
                    }
                    @include mobile_landscape{
                        width: calc(100% - 1rem);
                        padding: 0.5rem;
                    }
                    .question{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        
                        // margin-bottom: 10px;
                        .show{
                            cursor: pointer;
                            font-size: 1rem;
                            color: #fff;
                            transition: all 0.4s ease;
                            @include mobile_potrait{
                                font-size: 0.8rem;
                            }
                            @include mobile_landscape{
                                font-size: 0.8rem;
                            }
                        }
                        p{
                            font-size: 22px;
                            color: #fff;
                            transition: all 0.4s ease;
                            
                            @include mobile_potrait{
                                font-size: 0.8rem;
                            }
                            @include mobile_landscape{
                                font-size: 0.8rem;
                            }
                        }
                    }
                    .answer{
                        width: 100%;
                        color: #fff;
                        font-size: 1rem;
                        font-family: 'Montserrat', sans-serif;
                        overflow: hidden;
                        margin-bottom: 1vh;
                        transition: all 0.4s ease;
                        line-height: 1.5;
                        margin-top: 16px;
                        @include mobile_potrait{
                            font-size: 0.8rem;
                        }
                        @include mobile_landscape{
                            font-size: 0.8rem;
                        }
                    }
                }
            }
            .right{
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                @include mobile_potrait{
                    width: 100%;
                }
                img{
                    width: 70%;
                    @include mobile_potrait{
                        width: 90%;
                    }
                }
            }
        }
    }
    .effect{
        width: 50vw;
        height: 50vw;
        position: absolute;
        border-radius: 50%;
        background-image: radial-gradient( #C004DE99, #C004DE55, #C004DE00, #C004DE00);
        opacity: 0.3;
        z-index: 0;
        right: -20vw;
    }
}