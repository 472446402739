@import "../../../global.scss";
.newEra{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: auto;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 5rem;
    @include mobile_potrait{
        padding-bottom: 0rem;
    }
    .aboutContent{
        width: 100%;
        display: flex;
        z-index: 1;
        position: relative;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @include mobile_potrait{
            width: 90%;
        }
        
        .title{
            margin-bottom: 2rem;
        }

        .wrapper{
            width: 90%;
            display: flex;
            align-items: center;
            max-width: 1400px;
            position: relative;
            box-sizing: border-box;
            grid-area: auto;
            &.col_reverse{
                @include mobile_potrait{
                    flex-direction: column-reverse;
                    
                }
            }
           
            @include mobile_potrait{
                flex-direction: column;
                align-items: center;
                justify-content: center;
                max-width:  90%;
            }
            .text_div{
                width: 70%;
                display: flex;
                flex-direction: column;
                @include mobile_potrait{
                    width: 100%;
                    margin-bottom: 32px;
                }
                h2{
                    font-size: 40px;
                    color: #fff;
                    margin-bottom: 20px;
                    span{
                        color: #D8B08C;
                    }
                    @include mobile_potrait{
                        font-size: 24px;
                    }
                }
                .button{
                    width: fit-content;
                    min-width: 200px;
                    margin-top: 20px;
                }
            }
            .img_div{
                width: 30%;
                box-sizing: border-box;
                @include mobile_potrait{
                    width: 100%;
                    margin-bottom: 32px;
                }
                img{
                    width: 100%;
                }
                &.right{
                    padding-left: 52px;
                    @include mobile_potrait{
                        padding-left: 0px;
                    }
                }
                &.left{
                    padding-right: 52px;
                    @include mobile_potrait{
                        padding-right: 0px;
                    }
                }
            }
            
            p{
                font-size: min(32px, 2vw);
                color: #fff;
                @include mobile_potrait{
                    font-size: 0.8rem;
                }
                @include mobile_landscape{
                    font-size: 0.6rem;
                }
            }
        }
       
    }
    .effect{
        position: absolute;
        left: 0;
        top: 0;
        width: 30%;
    }
     
}