@import "../../../global.scss";
.helpModal{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left : 0;
    margin: 0;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.61);
    z-index: 25;
    display: none;
    opacity: 1;
    backdrop-filter: blur(5px);
    &.active{
        display: flex;
    }
    .modelContent{
        z-index: 5;
        width: 22rem;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        position: relative;
        opacity: 1;
        padding: 24px;
        backdrop-filter: blur(5px);
        background: linear-gradient(116.23deg, rgba(16, 100, 102, 0.0875) 2.48%, rgba(16, 100, 102, 0.245) 58.53%, rgba(16, 100, 102, 0.07) 119.46%), conic-gradient(from 169.71deg at 37.5% 31.16%, #FFFFFF01 0deg, rgba(255, 255, 255, 0) 50.63deg, #FFFFFF01 155.62deg, rgba(255, 255, 255, 0) 273.75deg, #FFFFFF01 360deg);
        border-width: 1px;
        border-style: dotted;
        border-color: grey;
        border-image-source: conic-gradient(from 169.71deg at 37.5% 31.16%, #FFFFFF33 0deg, rgba(255, 255, 255, 0) 50.63deg, #FFFFFF33 155.62deg, rgba(255, 255, 255, 0) 273.75deg, #FFFFFF33 360deg);
        border-image-slice: 1;
            
        @include mobile_potrait{
            width: 20rem;
            // height: 18rem;
        }
        
        .connectWalletHeader{
            width: 100%;
            border-top-left-radius: 2rem;
            border-top-right-radius: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            @include mobile_potrait{
                border-top-left-radius: 1rem;
                border-top-right-radius: 1rem;
            }
            .connectWalletTitle{
                
              font-size: 20px;
              font-weight: normal;
              max-width: 800px;
              margin-bottom: 10px;
              color: #00ffc8bb;
              text-transform: uppercase;
              text-align: center;
            }
            .connectModalCloseButton{
                font-size: 2rem;
                font-weight: 600;
                border: none;
                background-color: #00000000;
                border-radius: 16px;
                cursor: pointer;
                color: #00ffc8bb;
                margin-right: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.3s ease;
                position: absolute;
                top: 8px;
                right: 8px;
                z-index: 1;
                &:hover{
                    transform: rotate(45deg);
                }
            }
        }
        
        .connectWalletWrapper{
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            position: relative;
            p{
              width: 100%;
              font-size: 14px;
              font-weight: normal;
              color: #bdfff1;
              align-items: left;
              margin-bottom: 16px;
              span{
                color: #7bfde1;
              }
            }
        }
    }
}
