@import "../../../global.scss";
.contact{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: auto;
    width: 100%;
    box-sizing: border-box;
    padding-top: 6rem;
    padding-bottom: 5rem;
    @include mobile_potrait{
        padding-bottom: 2rem;
    }

    .content{
        width: 100%;
        display: flex;
        z-index: 1;
        position: relative;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        
        @include mobile_potrait{
            width: 90%;
            height: 90%;
            flex-direction: column;
        }
        .title{
            margin-bottom: 2rem;
            h1{
                text-align: center;
                @include mobile_potrait{
                    width: 100%;
                }
            }
        }
        .wrapper{
            width: 90%;
            display: flex;
            align-items: center;
            margin-bottom: 5rem;
            max-width: 1200px;
            @include mobile_potrait{
                flex-direction: column;
                align-items: center;
                justify-content: center;
                max-width:  90%;
            }
            .left{
                width: 50%;
                @include mobile_potrait{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 32px;
                }
                img{
                    width: 70%;
                }
            }
            .right{
                width: 50%;
                @include mobile_potrait{
                    width: 100%;
                }
                .myform{
                    border-radius: 24px;
                    backdrop-filter: blur(5px);
                    background: linear-gradient(116.23deg, rgba(16, 100, 102, 0.0875) 2.48%, rgba(16, 100, 102, 0.245) 58.53%, rgba(16, 100, 102, 0.07) 119.46%), conic-gradient(from 169.71deg at 37.5% 31.16%, #FFFFFF01 0deg, rgba(255, 255, 255, 0) 50.63deg, #FFFFFF01 155.62deg, rgba(255, 255, 255, 0) 273.75deg, #FFFFFF01 360deg);
                    border-width: 1px;
                    border-style: dotted;
                    border-color: grey;
                    border-image-source: conic-gradient(from 169.71deg at 37.5% 31.16%, #FFFFFF33 0deg, rgba(255, 255, 255, 0) 50.63deg, #FFFFFF33 155.62deg, rgba(255, 255, 255, 0) 273.75deg, #FFFFFF33 360deg);
                    border-image-slice: 1;
                    box-sizing: border-box;
                    padding: 46px 24px;
                    grid-area: auto;
                    gap : 16px;
                    display: flex;
                    flex-direction: column;
                    @include mobile_potrait{
                        border-radius: 16px;
                    }
                    .row{
                        width: 100%;
                    }
                    h3{
                        font-size: 18px;
                        color: #fff;
                        margin-bottom: 16px;
                    }
                    input, textarea{
                        background-color: #ffffff11;
                        border: none;
                        box-sizing: border-box;
                        padding: 10px;
                        width: 100%;
                        height: 60px;
                        border-radius: 10px;
                        opacity: 0.1px;
                        font-size: 18px;
                        font-weight: 800;
                        @include mobile_potrait{
                            font-size: 16px;
                            height: auto;
                            border-radius: 8px;
                        }
                    }
                    button{
                        width: 100%;
                        height: 60px;
                    }
                }
            }
           
            
            
        }
    }
    .effect1{
        position: absolute;
        width: 70%;
        right: 0;
        top: -15%;
        
        @include mobile_potrait{
            width: 30vw;
        }

    }
    .effect2{
        position: absolute;
        background: #10646675;
        border-radius: 50%;
        bottom: -50%;
        left: 0;
        width: 30%;
        height: 0;
        padding-bottom: 30%;
        filter: blur(100px);

    }
    
}