@import "../../../global.scss";
.accountModal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.61);
  z-index: 25;
  display: none;
  opacity: 1;
  backdrop-filter: blur(5px);
  &.active {
    display: flex;
  }
  .modelContent {
    z-index: 5;
    width: 22rem;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    opacity: 1;
    padding: 24px;
    @include mobile_potrait {
      width: 20rem;
    }
    @include mobile_landscape {
    }
    animation: modalAni 0.5s ease 0s 1 forwards;
    @keyframes modalAni {
      0% {
        transform: scale(0);
      }
      80% {
        transform: scale(1.2);
      }
      100% {
        transform: scale(1);
      }
    }
    .connectWalletHeader{
      width: 100%;
      // border-bottom: 1px solid #3bb2bfba;
      border-top-left-radius: 2rem;
      border-top-right-radius: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 1;
      @include mobile_potrait{
          border-top-left-radius: 1rem;
          border-top-right-radius: 1rem;
      }
      @include mobile_landscape{
          border-top-left-radius: 1rem;
          border-top-right-radius: 1rem;
      }
      .wallet_svg{
        display: inline-flex;
        align-self: center;
        flex-shrink: 0;
        width: 140px;
        height: 140px;
        margin-bottom: 16px;
        svg{
          width: 140px;
          height: 140px;
          display: inline-block;
          line-height: 1em;
          flex-shrink: 0;
          color: rgb(0, 255, 200);
          vertical-align: middle;
        }
      }
      .connectWalletTitle{
          
        font-size: 20px;
        font-weight: normal;
        max-width: 800px;
        margin-bottom: 10px;
        -webkit-text-stroke-width: 0.3px;
        -webkit-text-stroke-color: #00ffc8bb;
        -webkit-text-fill-color: transparent;
        text-transform: uppercase;
        text-align: center;
      }
      .connectModalCloseButton{
          font-size: 2rem;
          font-weight: 600;
          border: none;
          background-color: #00000000;
          border-radius: 16px;
          cursor: pointer;
          -webkit-text-stroke-width: 0.3px;
          -webkit-text-stroke-color: #00ffc8bb;
          -webkit-text-fill-color: transparent;
          margin-right: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s ease;
          position: absolute;
          top: 8px;
          right: 8px;
          &:hover{
              animation: closeAni 0.5s ease 0s infinite;
              @keyframes closeAni {
                  0%{
                      transform: translateX(0);
                  }
                  50%{
                      transform: translateX(0.5rem);
                  }
                  100%{
                      transform: translateX(0);
                  }
              }
          }
      }
  }

    .modalWraper {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      z-index: 1;
      padding-top: 16px;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 1rem;
        .avatar {
          width: 3rem;
        }
        input {
          border: none;
          background-color: #ffffff00;
          margin-left: 1rem;
          font-size: 1rem;
          color: #fff;
          width: 8rem;
          font-family: 'Montserrat', sans-serif;
          &:focus {
            outline: none;
          }
          @include mobile_potrait {
            width: 6rem;
            margin-left: 0.5rem;
            font-size: 0.8rem;
          }
          
        }
        .coppyAddressButton {
          cursor: pointer;
          border: none;
          background-color: #ffffff00;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1rem;
          transition: all 0.3s ease;
          &:hover {
            p {
              text-shadow: 3px 3px 10px #000;
            }
          }
          p {
            margin-left: 0.5rem;
            transition: all 0.3s ease;
            @include mobile_potrait {
              font-size: 0.8rem;
            }
            @include mobile_landscape {
              font-size: 0.8rem;
            }
          }
        }
      }
      .modalBtns {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: row;
        margin: 0 !important;
        a {
          cursor: pointer;
          border: none;
          background-color: #ffffff00;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1rem;
          transition: all 0.3s ease;
          text-decoration: none;
          &:hover {
            text-shadow: 3px 3px 5px #000;
          }
          .fas {
            margin-right: 0.5rem;
          }
          @include mobile_potrait {
            font-size: 0.8rem;
          }
          @include mobile_landscape {
            font-size: 0.8rem;
          }
        }
        .disconnect {
          padding: 8px 24px;
          border: 1px solid #00ffc80d;
          border-radius: 16px 0px 16px 0px;
          background: linear-gradient(132.97deg, #172b26e6 28.12%, #004b3be6 100%);
          &::before {
              background-color: #00ffc81a;
             
          }
          &:hover{
            border-color: #3bb2bf;
          }
           
          @include mobile_potrait {
            font-size: 0.8rem;
          }
          
        }
      }
    }

    .bg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
    }
  }
}