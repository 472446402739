@import "../../../global.scss";
.topbar{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px 32px;
    box-sizing: border-box;
    top: 0px;
    right: 0px;
    left: 0px;
    position: fixed;
    z-index: 900;
    width: 100%;
    background: linear-gradient(116.23deg, rgba(16, 100, 102, 0.0875) 2.48%, rgba(16, 100, 102, 0.245) 58.53%, rgba(16, 100, 102, 0.07) 119.46%);
// conic-gradient(from 169.71deg at 37.5% 31.16%, rgba(255, 255, 255, 0.68) 0deg, rgba(255, 255, 255, 0) 50.63deg, #FFFFFF 155.62deg, rgba(255, 255, 255, 0) 273.75deg, rgba(255, 255, 255, 0.68) 360deg);

    border-bottom: 1px solid rgba(255, 255, 255, 0.34);
    backdrop-filter: blur(15px);
    @include mobile_potrait{
        padding: 10px 16px;
    }
    .topbar_content{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1400px;
        .logo{
            height: 90%;
            display: flex;
            align-items: center;
            justify-content: center;
            @include mobile_potrait{
                height: auto;
                width: 100%;
                justify-content: flex-start;
                margin-bottom: 0.2rem;
            }
            @include mobile_landscape{
                margin-left: 0.5rem;
            }
            a{
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                
                img{
                    height: 37px;
                    @include mobile_potrait{
                        height: 2.5rem;
                    }
                    
                }
                
            }
        }
        .nav_list{
            display: flex;
            align-items: center;
            justify-content: center;
            grid-area: auto;
            gap : 16px;
            list-style: none;
            margin-left: 0px;
            margin-right: auto;
            @include mobile_potrait{
                display: none;
            }
            li{
                a{
                    color: #fff;
                    text-decoration: none;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &::after{
                        content : '';
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: #106466;
                        bottom: -10px;
                        opacity: 0;
                        transition: all 0.3s ease;
                    }
                    &.active{
                        &::after{
                            opacity: 1;
                        }
                    }
                    &:hover{
                        &::after{
                            opacity: 0.5;
                        }
                    }
                }
            }
        }
        .btns{
            display: flex;
            align-items: center;
            justify-content: center;
    
            @include mobile_potrait{
                margin-right: 40px;
                position: relative;
            }
            .col{
                display: flex;
                align-items: center;
                @include mobile_potrait{
                   position: absolute;
                   top: calc(100% + 5px);
                   right: -38px;
                }
            }
            .effect{
                height: 32px;
                width: 172px;
                color: #00ffc8;
                @include mobile_potrait{
                    display: none;
                }
            }
            .balance{
                height: 32px;
                box-sizing: border-box;
                padding: 8px 20px;
                gap: 4px;
                text-transform: uppercase;
                font-weight: 500;
                transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965) 0s;
                display: flex;
                -webkit-box-pack: justify;
                justify-content: space-between;
                flex-direction: row;
                position: relative;
                color: #bdfff1;
                overflow: hidden;
                outline: inherit;
                user-select: none;
                -webkit-box-align: center;
                align-items: center;
                width: fit-content;
                border-radius: 0px;
                text-align: left;
                cursor: pointer;
                border: 1px solid #00ffc80d;
                background: linear-gradient(132.97deg, #172b26e6 28.12%, #004b3be6 100%);
                &::before {
                    background-color: #00ffc81a;
                }
                @include mobile_potrait{
                    padding: 8px 10px;
                    
                }
                &:hover {
                    border-color: #00ffc855;
                }
                p{
                    color: #E2E8F0;
                    font-size: MIN(1vw, 14px);
                    text-transform: uppercase;
                    font-weight: normal;
                    z-index: 1;
                    white-space: nowrap;
                    span{
                        color: #bdfff1;
                        font-weight: 600;
                        font-family: 'Montserrat', sans-serif;
                    }
                    @include mobile_potrait{
                        font-size: 10px;
                    }
                    @include mobile_landscape{
                        font-size: 0.6rem;
                        margin: 0.2rem;
                    }
                }
                .pc{
                    @include mobile_potrait{
                        display: none;
                    }
                }
                img{
                    width: 24px;
                    height: 24px;
                }
            }
            .connectBtn{
                padding : 10px 20px;
                box-sizing: border-box;
                border: 1px solid #106466;
                border-radius: 8px;
                @include mobile_potrait{
                    border-radius: 0;
                    padding : 8px 10px;
                }
                
                
                p{
                    color: #fff;
                    font-size: MIN(1vw, 14px);
                    display: flex;
                    align-items: center;
                    grid-area: auto;
                    gap : 8px;
                    @include mobile_potrait{
                        font-size: 12px !important;
                        white-space: nowrap;
                        letter-spacing: -1px;
                    }
                    
                }
                img{
                    @include mobile_potrait{
                        display: none;
                    }
                }
            }
    
        }
    }



    
    .hamburger{
        display: none;
        width: 2.5rem;
        height: 3rem;
        position: absolute;
        right : 2rem;
        top : 1rem;
        flex-direction: column;
        justify-content: space-around;
        cursor: pointer;
        z-index: 6;
        overflow: hidden;
        transform: scale(0.9);
        
        @include mobile_potrait{
            display: flex;
            width: 42px;
            height: 34px;
            right : 6px;
            top : 15px;
            flex-direction: column;
            justify-content: space-around;
            cursor: pointer;
            z-index: 6;
            overflow: hidden;
            transform: scale(0.9);
            border: 1px solid #10646616;
            background: linear-gradient(116.23deg, #10646616 2.48%, rgba(16, 100, 102, 0.245) 58.53%, rgba(16, 100, 102, 0.07) 119.46%), conic-gradient(from 169.71deg at 37.5% 31.16%, #FFFFFF01 0deg, rgba(255, 255, 255, 0) 50.63deg, #FFFFFF01 155.62deg, rgba(255, 255, 255, 0) 273.75deg, #FFFFFF01 360deg);
            box-sizing: border-box;
            padding: 5px;
        }
        @include mobile_landscape{
            width: 2.5rem;
            height: 2.2rem;
            right : 1rem;
            top : 1.3rem;
            flex-direction: column;
            justify-content: space-around;
            cursor: pointer;
            z-index: 6;
            overflow: hidden;
            transform: scale(0.9);
        }
        span{
            width: 100%;
            height: 2px;
            border-radius: 3px;
            background-color: #fff;
            transform-origin: left;
            transition: all 1s ease;
        }
        &.active{
            z-index: 1;
            span{
                &:first-child{
                    width: 30px;
                    transform: rotate(40deg);
                    @include mobile_potrait{
                        transform: rotate(30deg);
                    }
                }
                &:nth-child(2){
                    opacity: 0;
                }
                &:last-child{
                    width: 30px;
                    @include mobile_potrait{
                        transform: rotate(-30deg);
                    }
                }

            }
        }
    }
}