@import "../../global.scss";
.btntimer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: auto;
    z-index: 1;
    width: 100%;
    span{
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: row;
        color: #8DD4D6;
        font-size: 20px;
        font-weight: 600;
        line-height: 1;
        box-shadow: none !important;
                            
        @include mobile_potrait{
            font-size:16px;
        }
        p{
            font-size: 13px !important;
            line-height: 1 !important;
            margin-left: 6px;
            color: #8DD4D6 !important;
            @include mobile_potrait{
                font-size: 12px !important;
                margin-left: 2px;
            }
        }
    }
    
}
.hiddenTimer{
    width: 0%;
}
.PreTimer{
    width: 100%;
    .PreTimerNums{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .number{
            width: 90%;
            height: 70%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: rgb(0, 0, 0);
            font-size: 1rem;
            margin: 1rem;
            
            @include mobile_potrait{
                margin: 0.2rem;
            }
            @include mobile_landscape{
                margin: 0.2rem;
            }
            .nums{
                color: white;
                font-size: 3.5rem;
                line-height: 4rem;
                font-family: 'Roboto', sans-serif;
                width: 5rem;
                height: 5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 5px #50FCC2 solid;
                border-radius: 50%;
                margin-bottom: 1rem;
                @include mobile_potrait{
                    font-size: 1.5rem;
                    line-height: 2rem;
                    width: 3rem;
                    height: 3rem;
                }
                @include mobile_landscape{
                    font-size:1.5rem;
                    line-height: 2rem;
                    width: 3rem;
                    height: 3rem;
                }
            }
            span{
                color: white;
                font-size: 1.5rem;
                font-family: 'Roboto', sans-serif;
                @include mobile_potrait{
                    font-size: 1rem;
                }
                @include mobile_landscape{
                    font-size: 0.8rem;
                }
            }
        }
    }
}